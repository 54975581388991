import React, { FunctionComponent } from 'react';
import { Layout, ExhibitorApp } from '../components';

const ExhibitorAppPage: FunctionComponent = () => (
  <Layout>
    <ExhibitorApp />
  </Layout>
);

export default ExhibitorAppPage;
